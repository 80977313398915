import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { SeuilService } from 'src/app/services/seuil/seuil.service';
import { ThousandSeparatorPipe } from '../../pipes/thousand-separator.pipe';
import {ISeuil} from "../../../interface/seuil.interface";

@Component({
  selector: 'app-modal-seuil',
  templateUrl: './modal-seuil.component.html',
  styleUrls: ['./modal-seuil.component.scss']
})
export class ModalSeuilComponent implements OnInit {

  @Input() seuil: ISeuil;
  modalTitle: string = 'Ajouter un seuil';
  constructor(
    private seuilService: SeuilService,
    public activeModal: NgbActiveModal,
    private toastr: ToastrService,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {

    if (this.seuil) {
      this.modalTitle = 'Modifier ce seuil'
    } else {
      this.seuil = {
        "code": "DIST",
        "libelle": "",
        "valeur": 0,
        regionLibelle: "",
        regionId: 0
      }
    }
  }

  update() {
    this.seuilService.update(this.seuil).subscribe({
      next: (response) => {
        if (!response.hasError) {
          this.toastr.success("Informations enregistrées avec succès.", "Succès")
          this.activeModal.close("success")
        } else {
          this.toastr.error(`Impossible de modifier le seuil. Erreur: ${response.status && response.status.hasOwnProperty('message') ? response.status.message : "Inconnue"}`, "Erreur")
        }
      },
      error: (error) => {
        console.error(`Erreur de modification du seuil. Erreur: ${JSON.stringify(error)}`)
        this.toastr.error("Nous ne parvenons pas à modifier le seuil. Veuillez reessayer plus tard.", "Erreur")
        throw new Error(`Erreur de modification du seuil. Erreur: ${JSON.stringify(error)}`);
      }
    })
  }

  onSubmit() {
    if (this.seuil.id) this.update()
  }

}
