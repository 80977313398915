import { Component, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { TemplateService } from 'src/app/services/template/template.service';
import { UtilitiesService } from 'src/app/services/utilities/utilities.service';
import { ZoneCommercialeService } from 'src/app/services/zone-commerciale/zone-commerciale.service';

@Component({
  selector: 'app-modal-import-zone',
  templateUrl: './modal-import-zone.component.html',
  styleUrls: ['./modal-import-zone.component.scss']
})
export class ModalImportZoneComponent implements OnInit {
  @Input() fileType: string;
  currentDate = new Date();
  fileName = '';
  file: File;
  constructor(
    public activeModal: NgbActiveModal,
    private zoneService: ZoneCommercialeService,
    private toastr: ToastrService,
    private renderer: Renderer2,
    private ngxUiLoaderService: NgxUiLoaderService,
    private elementRef: ElementRef,
    private templateService: TemplateService,
    private utilitiesService: UtilitiesService
  ) { }

  ngOnInit(): void {
  }

  downloadFile() {
    this.templateService.getTemplateFile("REGION").subscribe({
      next: (response) => {
        this.utilitiesService.downloadFile(response.body)
      }
    })
  }

  onFilechange(event: any) {
    this.file = event.target.files[0]
    this.fileName = event.target.files[0].name
  }

  uploadFile() {
    if (this.file && this.fileType) {
      this.ngxUiLoaderService.startLoader('modal-region')
      const uploadZoneRequest = this.zoneService.upload(this.file, this.fileType).subscribe({
        next: (response: any) => {
          if (response && response.hasOwnProperty('hasError') && !response.hasError) {
            this.toastr.success('Le fichier a été chargé avec succès', 'Information');
            return;
          } else {
            this.toastr.error('Oops! Nous ne parvenons pas à charger ce fichier', 'Information');
          }
        },
        error: (error: any) => {
          this.toastr.error('Oops! Une erreur inattendue a été rencontrée.', 'Error');
          this.ngxUiLoaderService.stopLoader('modal-region')
          throw new Error(error)
        },
        complete: () => {
          this.ngxUiLoaderService.stopLoader('modal-region')
          this.activeModal.close()
          console.log('Complete')
        }
      })
    } else {
      this.toastr.error('Veuillez selectionner un fichier', 'Information');
    }
  }

}
