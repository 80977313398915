import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subscription, filter, map, tap } from 'rxjs';
import { IMainKPI } from 'src/app/interface/dashboard.interface';
import { IDatepickerFilterParam } from 'src/app/interface/datepicker-filter.interface';
import { IHeureParam } from 'src/app/interface/heureParam.interface';
import { DashboardFilterDataService } from 'src/app/services/dashboard-filter-data/dashboard-filter-data.service';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { UtilitiesService } from 'src/app/services/utilities/utilities.service';

@Component({
  selector: 'app-main-kpi-card-list',
  templateUrl: './main-kpi-card-list.component.html',
  styleUrls: ['./main-kpi-card-list.component.scss']
})
export class MainKpiCardListComponent implements OnInit, OnDestroy {

  kpiDatas: IMainKPI;
  datePickerFilterData: IDatepickerFilterParam
  currentRegionId: number | string = ""
  currentRegionName: string = ""
  subscriptions: Subscription = new Subscription();
  constructor(
    private modalService: NgbModal,
    private dashboardService: DashboardService,
    private toastr: ToastrService,
    private ngxUiLoaderService: NgxUiLoaderService,
    private utilitiesService: UtilitiesService,
    private dashboardFilterService: DashboardFilterDataService
  ) { }

  ngOnInit(): void {
    this.handleDatePickerFilter()
    this.handleRegionChange()
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }

  /**
  * 
  * @param startDate 
  * @param endDate 
  * @param lastHour 
  * @param regionId 
  * @param isLastDate 
  */

  private getKPIDatas() {
    this.ngxUiLoaderService.startLoader("main-kpi-indicator");
    const getKPIRequest = this.dashboardService.getKPI(this.datePickerFilterData.startDate, this.datePickerFilterData.endDate, this.datePickerFilterData.startHour, this.datePickerFilterData.endHour, this.datePickerFilterData.isLastDate, this.currentRegionName).subscribe({
      next: (response: any) => {
        if (response && response.hasOwnProperty('hasError') && !response.hasError) {
          this.kpiDatas = response.item
        } else {
          this.toastr.error('Oops! Nous ne parvenons pas à récupérer les indicateurs de performance', 'Information');
        }
      },
      error: (error: any) => {
        this.toastr.error('Oops! Une erreur inattendue a été rencontrée.', 'Error');
        throw new Error(error)
      },
      complete: () => {
        this.ngxUiLoaderService.stopLoader("main-kpi-indicator");
      }
    })

    this.subscriptions.add(getKPIRequest)
  }

  handleRegionChange() {
    this.utilitiesService.omerMapSourceObservable$.pipe(
      filter(data => data.eventType === "current_zone"),
      tap(data => this.currentRegionName = data.datas.zoneName)
    ).subscribe({
      next: (response) => {
        //Getting Datas
        this.getKPIDatas()
      },
      error: (error) => {
        this.utilitiesService.handleHTTPError(error)
      }
    })
  }

  //Processing Date Picker Filter Change
  handleDatePickerFilter() {
    this.dashboardFilterService.currentFilterState.pipe(
      filter(filterItem => filterItem.startDate !== "" && filterItem.endDate !== "" && filterItem.startHour !== ""),
      tap(filterItem => this.datePickerFilterData = filterItem)
    ).subscribe({
      next: (response) => {
        //Getting Datas
        this.getKPIDatas()
      },
      error: (error) => {
        this.utilitiesService.handleHTTPError(error)
      }
    })
  }

  openModal(content: any) {
    return
    // this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(
    //   (result) => {
    //     this.closeResult = `Closed with: ${result}`;
    //   },
    //   (reason) => {
    //     this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    //   },
    // );
  }

}
