import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IDistributeur, IDistributeurBody } from 'src/app/interface/distributeur.interface';
import { RestClientService } from '../rest-client.service';
import { UserService } from '../user/user.service';
import {DateFilterParam, LocationFilterParam} from "../../types/filter-param.type";
import {QueryBodyDatas, StockStat, StockStatDatasByCategorie} from "../../types/stock-level.type";

@Injectable({
  providedIn: 'root'
})
export class DistributeurService {
  private readonly basePath: string = 'distributeurs'
  private readonly historicBasePath: string = 'omerDistHist'
  private readonly defaultBody: {
    id: string,
    code: string,
    libelle: string,
    numeroDealer: string,
    territoirLibelle: string,
    createdAt: string,
    updatedAt: string,
    deletedAt: string,
    createdBy: string,
    updatedBy: string,
    deletedBy: string,
    isDeleted: string,
    territoirLibelleParam?: {
      operator: string,
      datas: string[] | 'all'
    },
    territoirIdParam?: {
      operator: string,
      datas: string[] | 'all'
    }
  } = {
      id: "",
      code: "",
      libelle: "",
      numeroDealer: "",
      territoirLibelle: "",
      createdAt: "",
      updatedAt: "",
      deletedAt: "",
      createdBy: "",
      updatedBy: "",
      deletedBy: "",
      isDeleted: "",
    };

  private readonly dealerDefaultBody: {
    key: string,
    date_param: DateFilterParam,
    heure_param: DateFilterParam,
    region_param?: LocationFilterParam,
    territoire_param?: LocationFilterParam
  } = {
    "key": "",
    "date_param": {
      "operator": "[]",
      "start": "",
      "end": ""
    },
    "heure_param": {
      "operator": "[]",
      "start": "",
      "end": ""
    }
  }
  constructor(private restClient: RestClientService, private user: UserService) { }

  get(page: number = 0, dataLength: number | string = 10, searchValue: string = ''): Observable<any> {
    const datas = { ...this.defaultBody }
    if (searchValue && searchValue.length > 0) datas.numeroDealer = searchValue

    const bodyDatas = {
      "user": this.user.userMatricule,
      "index": page,
      "size": dataLength,
      "data": datas
    }
    return this.restClient.post(`${this.basePath}/getByCriteria`, bodyDatas)
  }
  findOne(id: number): Observable<any> {
    const data = { ...this.defaultBody }
    data.id = id.toString()
    const datas = {
      "user": this.user.userMatricule,
      "data": data
    }
    return this.restClient.post(`${this.basePath}/getByCriteria`, datas)
  }
  create(datas: IDistributeur): Observable<any> {
    const bodyDatas = {
      "user": this.user.userMatricule,
      "datas": [datas]
    }
    return this.restClient.post(`${this.basePath}/create`, bodyDatas)
  }
  update(datas: IDistributeur): Observable<any> {
    const bodyDatas = {
      "user": this.user.userMatricule,
      "datas": [datas]
    }
    return this.restClient.post(`${this.basePath}/update`, bodyDatas)
  }
  upload(file: File) {
    let formParams = new FormData();
    formParams.append('file', file)
    formParams.append('user', this.user.userMatricule)

    return this.restClient.post(`${this.basePath}/uploadDistributeur`, formParams)
  }
  getUploadedFilePath(): Observable<any> {
    return this.restClient.get(`${this.basePath}/getGeojsonFile`)
  }
  delete(id: number): Observable<any> {
    const bodyDatas = {
      "user": this.user.userMatricule,
      "datas": [{ id }]
    }
    return this.restClient.post(`${this.basePath}/delete`, bodyDatas)
  }
  getLastStockUpdate(): Observable<any> {
    const bodyDatas = {
      "user": this.user.userMatricule,
      "data": {}
    }
    return this.restClient.post(`${this.historicBasePath}/getLastHour`, bodyDatas)
  }
  searchByTerritories(term: string, territoriesIds?: string[] | 'all') {
    const data = { ...this.defaultBody }
    data.numeroDealer = term
    if (territoriesIds && territoriesIds?.length > 0) {
      data.territoirLibelleParam = {
        operator: "IN",
        datas: territoriesIds
      }
    }

    const bodyDatas = {
      "user": this.user.userMatricule,
      "data": { ...data }
    }
    return this.restClient.post(`${this.basePath}/getByCriteria`, bodyDatas)
  }
  searchByTerritoriesNames(payload: {
    searchKey: string,
    date_param: DateFilterParam,
    heure_param: DateFilterParam,
    region_param?: LocationFilterParam,
    territoire_param?: LocationFilterParam
  }) {
    const requestBody = { ...this.dealerDefaultBody }
    requestBody.heure_param = payload.heure_param
    requestBody.date_param = payload.date_param
    requestBody.key = payload.searchKey


    if("region_param" in payload){
      Object.assign(requestBody, {
        region_param: payload.region_param
      });
    }
    if("territoire_param" in payload){
      Object.assign(requestBody, {
        territoire_param: payload.territoire_param
      });
    }

    const bodyDatas = {
      index: 0,
      size: 10,
      "data": requestBody
    }

    return this.restClient.post('dashbord/getAllDistributeurs', bodyDatas)
  }
  searchDistributeurByTerritories(ids: string[]) {
    const data = { ...this.defaultBody }
    if (ids && ids.length > 0) {
      data.territoirIdParam = {
        operator: "IN",
        datas: ids
      }
    }

    const bodyDatas = {
      "user": this.user.userMatricule,
      "data": { ...data }
    }
    return this.restClient.post(`${this.basePath}/getByCriteria`, bodyDatas)
  }
  getAllByFilters(
    searchTerm: string,
    startDate: string,
    endDate: string,
    starTime: string,
    endTime: string,
    regions: string[] | 'all',
    territories: string[] | 'all',
    distributors: string[] | 'all',
    categorie_DISTRIBUTEUR_param: string[],
    categorie_PDV_param: string[],
    index: number,
    size: number
  ) {
    let bodyDatas: {
      user: string;
      index: number;
      size: number;
      data: QueryBodyDatas & {
        categorie_DISTRIBUTEUR_param?: {
          operator: string,
          datas: string[]
        };
        categorie_PDV_param?: {
          operator: string,
          datas: string[]
        };
      };
    } = {
      user: this.user.userMatricule,
      index,
      size,
      data: {
        date_param: {
          operator: "[]",
          start: startDate,
          end: endDate
        },
        heure_param: {
          operator: "[]",
          start: starTime,
          end: endTime
        }
      }
    }

    if (searchTerm != '') {
      bodyDatas.data.key = searchTerm
    }

    if (regions != 'all' && regions.length > 0) {
      bodyDatas.data.region_param = {
        operator: "IN",
        datas: regions
      }
    }
    if (territories != 'all' && territories.length > 0) {
      bodyDatas.data.territoire_param = {
        operator: "IN",
        datas: territories
      }
    }
    if (distributors != 'all' && distributors.length > 0) {
      bodyDatas.data.numero_DISTRIBUTEUR_param = {
        operator: "IN",
        datas: distributors
      }
    }

    if(categorie_DISTRIBUTEUR_param && categorie_DISTRIBUTEUR_param.length > 0) {
      bodyDatas.data.categorie_DISTRIBUTEUR_param = {
        operator: "IN",
        datas: categorie_DISTRIBUTEUR_param
      }
    }

    if(categorie_PDV_param && categorie_PDV_param.length > 0) {
      bodyDatas.data.categorie_PDV_param = {
        operator: "IN",
        datas: categorie_PDV_param
      }
    }

    return this.restClient.post<{
      hasError: boolean,
      count: number,
      sumTotalDistribDistributeur: number,
      sumTotalDistribDistributeurPlain: string,
      sumTotalPdvDistributeur: number,
      sumTotalPdvDistributeurPlain: string,
      itemsDistributeurs: (StockStat & StockStatDatasByCategorie)[]
    }>('dashbord/getAllDistributeurs', bodyDatas)
  }

  export(): Observable<any> {
    const data = { ...this.defaultBody }
    const bodyDatas = {
      "user": this.user.userMatricule,
      "data": data
    }

    let httpOptions = { responseType: 'arraybuffer', observe: 'response' }

    return this.restClient.post(`${this.basePath}/exportDistributeurs`, bodyDatas, httpOptions)
  }
}
