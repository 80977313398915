import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { TerritoireCommercialService } from 'src/app/services/territoire-commercial/territoire-commercial.service';
import { ZoneCommercialeService } from 'src/app/services/zone-commerciale/zone-commerciale.service';
import { DistributeurService } from 'src/app/services/distributeur/distributeur.service';
import { PdvService } from 'src/app/services/pdv/pdv.service';
import {IDatepickerFilterParam} from "../../../../../interface/datepicker-filter.interface";

export type FilterTypes = 'territories' | 'distributors' | 'pdvs' | 'localities' | 'categorie_DISTRIBUTEUR_param' | 'categorie_PDV_param'
export interface FilterItem {
  label: string;
  id: string;
}

export interface Filters {
  regions: string[] | 'all';
  territories: string[] | 'all';
  localities: string[] | 'all';
  distributors: string[] | 'all';
  pdvs: string[] | 'all';
  categorie_DISTRIBUTEUR_param: string[];
  categorie_PDV_param: string[];
  thirtyDays: boolean;
}

@Injectable()
export class FiltersService {
  private selectedRegions: FilterItem[] | 'all' = [];
  private selectedTerritories: string[] | 'all' = [];
  private categorie_DISTRIBUTEUR_param: string[] = [];
  private categorie_PDV_param: string[] = [];
  private selectedLocalities: string[] | 'all' = [];
  private selectedDistributors: string[] | 'all' = [];
  private selectedPdvs: string[] | 'all' = [];
  private thirtyDaysFilter: boolean;

  filtersSubjects = {
    territories: new BehaviorSubject<FilterItem[]>([]),
    localities: new BehaviorSubject<FilterItem[]>([]),
    distributors: new BehaviorSubject<FilterItem[]>([]),
    dealerCategory: new BehaviorSubject<FilterItem[]>([]),
    pdvCategory: new BehaviorSubject<FilterItem[]>([]),
    pdvs: new BehaviorSubject<FilterItem[]>([]),
  };

  filters = new Subject<Filters>()

  constructor(
    private zoneCommercialeService: ZoneCommercialeService,
    private territoireCommercialService: TerritoireCommercialService,
    private distributeurService: DistributeurService,
    private pdvService: PdvService,
  ) { }

  emitFilters() {
    this.filters.next({
      regions: this.selectedRegions =='all' ? 'all' : this.selectedRegions.map(filterRegionItem=>filterRegionItem.label),
      territories: this.selectedTerritories,
      localities: this.selectedLocalities,
      distributors: this.selectedDistributors,
      pdvs: this.selectedPdvs,
      categorie_DISTRIBUTEUR_param: this.categorie_DISTRIBUTEUR_param,
      categorie_PDV_param: this.categorie_PDV_param,
      thirtyDays: this.thirtyDaysFilter
    })
  }

  updateThirtyDaysFilter(newValue: boolean) {
    this.thirtyDaysFilter = newValue
  }

  updateSelectedRegions(regions: FilterItem[]) {
    this.selectedRegions = regions
  }

  updateSelectedFilters(source: FilterTypes, selectedItems: FilterItem[] | 'all') {
    const selectedItemsIds = selectedItems == 'all' ? 'all' : selectedItems.filter(item => item.label.length > 0).map(item => item.label)

    switch (source) {
      case 'territories':
        this.selectedTerritories = selectedItemsIds
        break;
      case 'localities':
        this.selectedLocalities = selectedItemsIds
        break;
      case 'distributors':
        this.selectedDistributors = selectedItemsIds
        break;
      case 'pdvs':
        this.selectedPdvs = selectedItemsIds
        break;
      case 'categorie_DISTRIBUTEUR_param':
        this.categorie_DISTRIBUTEUR_param = selectedItemsIds as any
        break;
      case 'categorie_PDV_param':
        this.categorie_PDV_param = selectedItemsIds as any
        break;
    }
  }
  searchFilterItems(source: Exclude<FilterTypes, "categorie_DISTRIBUTEUR_param" | "categorie_PDV_param">, term: string, dateFilterParam?: IDatepickerFilterParam) {
    switch (source) {
      case 'territories':
        return this.getTerritories(term)
      case 'localities':
        return this.getLocalities(term)
      case 'distributors':
        return this.getDistributors(term)
      case 'pdvs':
        return this.getPdvs(term)
    }
  }

  handleItemsApiResponses(source: Exclude<FilterTypes, "categorie_DISTRIBUTEUR_param" | "categorie_PDV_param">) {
    switch (source) {
      case 'territories':
        return ({ items }: { items: any }) => {
          const filterItems: FilterItem[] = items.map((item: any) => {
            return {
              label: item.libelle,
              id: item.id
            }
          })
          this.filtersSubjects.territories.next(filterItems)
        }
      case 'localities':
        return ({ items }: { items: any }) => {
          const filterItems: FilterItem[] = items.map((item: any) => {
            return {
              label: item.libelle,
              id: item.id
            }
          })
          this.filtersSubjects.localities.next(filterItems)
        }
      case 'distributors':
        return ({ items }: { items: any }) => {
          const filterItems: FilterItem[] = items.map((item: any) => {
            return {
              label: item.numeroDealer,
              id: item.id
            }
          })
          this.filtersSubjects.distributors.next(filterItems)
        }
      case 'pdvs':
        return ({ items }: { items: any }) => {
          const filterItems: FilterItem[] = items.map((item: any) => {
            return {
              label: item.key,
              id: item.key
            }
          })
          this.filtersSubjects.pdvs.next(filterItems)
        }
    }
  }

  getRegions(): Observable<any> {
    return this.zoneCommercialeService.get()
  }

  getTerritories(term?: string): Observable<any> {
    return this.territoireCommercialService.searchByRegion(term!, this.selectedRegions=='all' ? 'all' :  this.selectedRegions.map(regionFilterItem=>regionFilterItem.id))
  }

  getLocalities(term?: string): Observable<any> {
    return this.territoireCommercialService.searchByRegion(term!, this.selectedRegions=='all' ? 'all' :  this.selectedRegions.map(regionFilterItem=>regionFilterItem.id))
  }

  getPdvs(term?: string): Observable<any> {
    return this.pdvService.searchByDistributors(term!, this.selectedDistributors, this.thirtyDaysFilter)
  }

  getDistributors(term?: string): Observable<any> {
    return this.distributeurService.searchByTerritories(term!, this.selectedTerritories)
  }
}

