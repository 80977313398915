<div class="fieldset">
  <div class="head">
    <h5>{{ title }}</h5>
    <div class="form-check checkbox-rect">
      <input class="form-check-input" type="checkbox"  [(ngModel)]="allSelected" (change)="toggleAllSelected()" [id]="'filter-'+type+'-select-all'">
      <label class="form-check-label" [for]="'filter-'+type+'-select-all'">
        Tous
      </label>
    </div>
  </div>
  <div>
    <div class="fieldset-control">
      <div *ngIf="selectedItems.length>0" class="selected-items-container">
        <div class="selected-item" *ngFor="let item of selectedItems">
          <div class="col-md-9 selected-item-label">
            <div>{{ item.label }}</div>
          </div>
          <div class="col-md-3 remove-item" (click)="removeItem(item.id)">
            <span aria-hidden="true">&times;</span>
          </div>
        </div>
      </div>
      <input [type]="inputSearchType" #searchInput [placeholder]="type === 'distributors' ? 'Rechercher par nom ou numéro' : ''" class="input-search">
    </div>
    <ul class="item-list mb-0">
      <li class="item-list-item filter-selection" *ngFor="let item of items" (click)="selectItem(item)">{{ item.label }}</li>
    </ul>
  </div>
  @if(type === 'distributors'){
    <div class="my-2 px-3">
      <label for="dealerCategoryFilter" class="form-label">Ou sélectionner une catégorie de distributeur</label>
      <select name="dealerCategoryFilter" id="dealerCategoryFilter" class="form-select category-select" [(ngModel)]="dealerCategory" (change)="handleCategoryFilter()">
        <option [value]="null" selected>Toutes les catégories</option>
        @for(category of dealerCategoryList; track category.code){
          <option [value]="category.code">{{ category.title }}</option>
        }
      </select>
    </div>
  }
  @if(type === 'pdvs'){
    <div class="my-2 px-3">
      <label for="pdvCategoryFilter" class="form-label">Ou sélectionner une catégorie de PDV</label>
      <select name="pdvCategoryFilter" id="pdvCategoryFilter" class="form-select category-select" [(ngModel)]="pdvCategory" (change)="handleCategoryFilter()">
        <option [value]="null" selected>Toutes les catégories</option>
        @for(category of pdvCategoryList; track category.code){
          <option [value]="category.code">{{ category.title }}</option>
        }
      </select>
    </div>
  }
</div>
